import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import DistributorSuperstockist from "./Pages/DistributorSuperstockist";
import LoginPage from "./Pages/LoginPage";
import Career from "./Pages/Career";
import CareerList from "./Pages/CareerList";
import DistributorSuperstockistList from "./Pages/DistributorSuperstockistList";
import ComplaintList from "./Pages/ComplaintList";
import Complaint from "./Pages/Complaint";
import FeedBack from "./Pages/FeedBack";
import FeedBackList from "./Pages/FeedBackList";
import SalesManList from "./Pages/SalesManList";
import Distributors from "./Pages/Distributors";
import SalesManDistributorWise from "./Pages/SalesManDistributorWise";
import DistributorSalesManWise from "./Pages/DistributorSalesManWise";
import SuperStockists from "./Pages/SuperStockists";
import SuperStockistsWiseSalesMan from "./Pages/SuperStockistsWiseSalesMan";

import UserWiseOrders from "./Pages/UserWiseOrders";
import SalesManWiseOrder from "./Pages/SalesManWiseOrder";
import CompanyWiseOrder from "./Pages/CompanyWiseOrder";
import MyProfile from "./Pages/MyProfile";
import { ToastContainer } from "react-toastify";
import SalesManWiseDeliverdOrder from "./Pages/SalesManWiseDeliverdOrder";
import CompanyWiseDeliverdOrder from "./Pages/CompanyWiseDeliverdOrder";
import UserWiseDeliverdOrders from "./Pages/UserWiseDeliverdOrders";
import NotFoundPage from "./Components/NotFoundPage";
import ASMList from "./Pages/ASMList";
import SalesManASMwise from "./Pages/SalesManASMwise";
import Reporting from "./Pages/Reporting";
import AsmDistributorWiseProductReport from "./Pages/AsmDistributorWiseProductReport";
import SalesManMonthWiseReport from "./Pages/SalesManMonthWiseReport";
import AsmMonthWiseReport from "./Pages/AsmMonthWiseReport";
import SoDistributorWiseProductReport from "./Pages/SoDistributorWiseProductReport";
import CompanyStateDisWiseMonthlyReport from "./Pages/CompanyStateDisWiseMonthlyReport";
import CompanyStateDisWiseProductReport from "./Pages/CompanyStateDisWiseProductReport";
import SuperStockistsMonthlyReport from "./Pages/SuperStockistsMonthlyReport";
import SuperStockistsProductReport from "./Pages/SuperStockistsProductReport";
import AsmWiseOrder from "./Pages/AsmWiseOrder";
import AsmWiseDeliverdOrder from "./Pages/AsmWiseDeliverdOrder";
import OrderdDeliverdMonthly from "./Pages/OrderdDeliverdMonthly";
import OrderdDeliverdProduct from "./Pages/OrderdDeliverdProduct";
import Thankyou from "./Pages/Thankyou";
import SuperStockistsOrderMonthlyReport from "./Pages/SuperStockistsOrderMonthlyReport";
import SuperStockistsOrderProductReport from "./Pages/SuperStockistsOrderProductReport";
import SuperStockistsClosingStock from "./Pages/SuperStockistsClosingStock";
import OpningColsingStock from "./Pages/OpningColsingStock";
import WebSiteContact from "./clientContactWeb/WebSiteContact";
import WebSiteContactList from "./clientContactWeb/WebSiteContactList";
import ClientReviewList from "./clientReviewWeb/ClientReviewList";
import NewsletterList from "./newsletterWeb/NewsletterList";
import ContractorList from "./Contractor/ContractorList";
import ContractorsAttendance from "./Contractor/ContractorsAttendance";
import CategoryList from "./category/CategoryList";
import GroupsList from "./groups/GroupsList";
import Products from "./products/Products";
import IndustrialVisitList from "./industrialVisitWeb/IndustrialVisitList";
import GroupWiseReport from "./Pages/GroupWiseReport";
import UserWiseTargate from "./Targate/UserWiseTargate";
import CatWiseReport from "./Pages/CatWiseReport";
import TargetVsActivement from "./Pages/TargetVsActivement";
import PrimaryBillingReport from "./Pages/PrimaryBillingReport";
import WebBannersList from "./bannersWeb/WebBannersList";
import CareerOpeningList from "./careeropening/CareerOpeningList";
import DilershipOpeningList from "./dilershipopening/DilershipOpeningList";
import LocalSale from "./Pages/LocalSale";
import FocusTargetActivement from "./Pages/FocusTargetActivement";
import DepartmentList from "./department/DepartmentList";
import BlogList from "./blog/BlogList";
import StateWiseDistributorSenior from "./stateWiseDistributorSenior/StateWiseDistributorSenior";
function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  useEffect(() => {
    if (utmSource && utmMedium) {
      localStorage.setItem("utm_source", utmSource);
      localStorage.setItem("utm_Medium", utmMedium);
    }
  },[utmSource, utmMedium])
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/career" element={<Career />} />
        <Route path="/distributor" element={<DistributorSuperstockist />} />
        <Route path="/distributor-list" element={<DistributorSuperstockistList />}/>
        <Route path="/career-list" element={<CareerList />} />
        <Route path="/salesman-list" element={<SalesManList />} />
        <Route path="/asm-list" element={<ASMList />} />
        {/* report */}
        <Route path="/reports" element={<Reporting />} />
        <Route path="/report" element={<CompanyStateDisWiseMonthlyReport />} />
        <Route path="/product-report" element={<CompanyStateDisWiseProductReport />}/>
        <Route path="/group-wise" element={<GroupWiseReport />}/>
        <Route path="/cat-wise" element={<CatWiseReport />}/>
        <Route path="/primary-billing-report" element={<PrimaryBillingReport />}/>
        <Route path="/asm-disrtibutor-product-reporting" element={<AsmDistributorWiseProductReport />}/>
        <Route path="/asm-monthly-reporting" element={<AsmMonthWiseReport />} />
        <Route path="/so-wise-monthly-reporting" element={<SalesManMonthWiseReport />}/>
        <Route path="/so-wise-product-reporting" element={<SoDistributorWiseProductReport />} />
        <Route path="/dispatchby-wise-monthly-reporting" element={<SuperStockistsMonthlyReport />} />
        <Route path="/dispatchby-wise-product-reporting" element={<SuperStockistsProductReport />} />
        <Route path="/target-vs-achievement" element={<TargetVsActivement />} />
        <Route path="/focus-target-vs-achievement" element={<FocusTargetActivement />} />
        <Route path="/dispatchby-order-wise-monthly-reporting" element={<SuperStockistsOrderMonthlyReport />} />
        <Route path="/opening-closing-stock" element={<OpningColsingStock />} />
        <Route path="/dispatchby-order-wise-product-reporting" element={<SuperStockistsOrderProductReport />} />
        <Route
          path="/orderd-deliverd-monthly"
          element={<OrderdDeliverdMonthly />}
        />
        <Route
          path="/orderd-deliverd-product"
          element={<OrderdDeliverdProduct />}
        />
        {/* end report */}
        <Route path="/salesman-asm-wise/:name" element={<SalesManASMwise />} />
        <Route
          path="/salesman-distributor-wise/:name"
          element={<SalesManDistributorWise />}
        />
        <Route
          path="/distributor-salesman-wise/:name"
          element={<DistributorSalesManWise />}
        />
        <Route
          path="/distributor-salesman-wise"
          element={<DistributorSalesManWise />}
        />
        <Route
          path="/super-stockists-wise-salesman/:name"
          element={<SuperStockistsWiseSalesMan />}
        />
        <Route
          path="/super-stockists-wise-salesman"
          element={<SuperStockistsWiseSalesMan />}
        />
        <Route path="/complaints" element={<ComplaintList />} />
        <Route path="/user-complaint" element={<Complaint />} />
        <Route path="/feedback" element={<FeedBackList />} />
        <Route path="/user-feedback" element={<FeedBack />} />
        <Route path="/web-contact" element={<WebSiteContact />} />
        <Route path="/web-contact-list" element={<WebSiteContactList />} />
        <Route path="/client-review-list" element={<ClientReviewList/>} />
        <Route path="/career-opening" element={<CareerOpeningList/>} />
        <Route path="/dilership-opening" element={<DilershipOpeningList/>} />
        <Route path="/banner" element={<WebBannersList/>} />
        <Route path="/blog" element={<BlogList/>} />
        <Route path="/industrial-visit" element={<IndustrialVisitList/>} />
        <Route path="/contractors" element={<ContractorList/>} />
        <Route path="/contractor" element={<ContractorsAttendance/>} />
        <Route path="/categorys" element={<CategoryList/>} />
        <Route path="/department" element={<DepartmentList/>} />
        <Route path="/state-wise-distributor-senior" element={<StateWiseDistributorSenior/>} />
        <Route path="/groups" element={<GroupsList/>} />
        <Route path="/newsletter-list" element={<NewsletterList/>} />
        <Route path="/created-distributor" element={<Distributors />} />
        <Route path="/inactive-distributor" element={<Distributors />} />
        <Route path="/user-wise-target" element={<UserWiseTargate />} />
        <Route path="/super-stockist" element={<SuperStockists />} />
        <Route path="/super-stockist-closing-stock" element={<SuperStockistsClosingStock />} />
        <Route path="/products" element={<Products />} />
        <Route path="/distributor-orders" element={<UserWiseOrders />} />
        <Route path="/distributor-orders/:name" element={<UserWiseOrders />} />
        <Route path="/distributor-delivered-orders" element={<UserWiseDeliverdOrders />} />
        <Route path="/distributor-delivered-orders/:name" element={<UserWiseDeliverdOrders />} />
        <Route path="/salesman-wise-orders/:name" element={<SalesManWiseOrder />} />
        <Route path="/asm-wise-orders/:name" element={<AsmWiseOrder />} />
        <Route path="/asm-wise-delivered-orders/:name" element={<AsmWiseDeliverdOrder />} />
        <Route path="/salesman-wise-orders" element={<SalesManWiseOrder />} />
        <Route path="/local-sale" element={<LocalSale />} />
        <Route path="/salesman-wise-delivered-orders" element={<SalesManWiseDeliverdOrder />} />
        <Route path="/salesman-wise-delivered-orders/:name" element={<SalesManWiseDeliverdOrder />} />
        <Route path="/company-wise-orders/:name" element={<CompanyWiseOrder />} />
        <Route path="/company-delivered-orders/:name" element={<CompanyWiseDeliverdOrder />} />
        <Route path="/company-wise-orders" element={<CompanyWiseOrder />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/thankyou-distributor" element={<Thankyou />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
export default App;
