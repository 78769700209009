import React, { useEffect, useState } from "react";
import DistributorUpdate from "../Components/DistributorUpdate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  getDistributorList,
  getStatesList,
  getDistrictList,
  deleteDistributorRecord,
  getOrderListRest,
  getSalesPersonWithoutPage,
  getDispatchByWithOutPage,
} from "../reducers/commonReducer";
import DistributorView from "../Components/DistributorView";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import DistributorAdd from "../Components/DistributorAdd";

const DistributorSuperstockistList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [states, setStates] = useState();
  const [page, setPage] = useState(1);
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { distributorList, distributorListBlank, statesList, getDistrict } =
    useSelector((state) => state.commonReducer);

  const header = [
    "Sr",
    "Name",
    "Email",
    "FirmName",
    "Date",
    "ContactNo",
    "GSTIN",
    "State",
    "District",
    "Town",
    "Comment",
    "UserComment",
    "Status",
    "Distance",
    "Source",
    "AlreadyWorkingDetails",
    "AlreadyBusinessBrand"
  ];
  let body = [];
  !!distributorList &&
    distributorList.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: data.name,
        Email: data?.email,
        FirmName: data?.firmName,
        Date: moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        ContactNo: data?.number,
        GSTIN: data?.GSTIN,
        State: data?.address?.state,
        District: data?.address?.district,
        town: data?.address?.town,
        Comment: data?.comment,
        UserComment: data?.userComment,
        Status: data?.status,
        Distance: data?.distance,
        Source: `${data?.utm_source?.length > 0 && data?.utm_source} ${data?.utm_Medium ? `/` : null} ${data?.utm_Medium}`,
        AlreadyWorkingDetails: data?.exactParty,
        AlreadyBusinessBrand : data?.alreadyBusinessBrand
      });
    });
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getDistributorList());
    localStorage.getItem("userRole") !== 9 &&
      dispatch(getDispatchByWithOutPage());
    dispatch(getSalesPersonWithoutPage());
    dispatch(getStatesList());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              value={states}
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStates(e.target.value);
                dispatch(
                  getDistributorList({
                    state: e.target.value,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {statesList.map((data, index) => {
                return (
                  <option
                    className="first-latter-capital"
                    key={index}
                    value={data.state}
                  >
                    {data.state}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              value={district}
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                await setDistrict(e.target.value);
                dispatch(
                  getDistributorList({
                    state: states,
                    district: e.target.value,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="district"
              className="form-control"
            >
              <option value="">Select District</option>
              {getDistrict.map((data, index) => {
                return (
                  <option
                    className="first-latter-capital"
                    key={index}
                    value={data}
                  >
                    {data}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setUserStatus(e.target.value);
                dispatch(
                  getDistributorList({
                    state: states,
                    district,
                    userStatus: e.target.value,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Status</option>
              <option value="Pending">Pending</option>
              {/* <option value={"Selected"}>Selected</option> */}
              <option value={"Confirmd"}>Confirmd</option>
              <option value={"Read"}>Read</option>
              <option value={"Discussion"}>Discussion</option>
              <option value={"Reject"}>Reject</option>
              <option value={"ForFuture"}>For Future</option>
              <option value={"Warm"}>Warm</option>
              <option value={"Hot"}>Hot</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStartDate(e.target.value);
                dispatch(
                  getDistributorList({
                    state: states,
                    district,
                    userStatus,
                    startDate: e.target.value,
                    endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setEndDate(e.target.value);
                dispatch(
                  getDistributorList({
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md={2} sm={12} className="mbt-phone">
            <ExportData
              title="Export Distributor"
              header={header}
              body={body}
              tableName="Get ShyamG Distributorship"
            />
          </Col>
        </Row>

        <table className="table table-scrolling ">
          <thead className="normalplace">
            <tr>
              <th scope="col">Sr</th>
              <th className="over-col-size" scope="col">
                Address
              </th>
              <th scope="col">Contact No.</th>
              <th scope="col">Date</th>
              <th className="over-col-size" scope="col">
                User Comment
              </th>
              <th className="over-col-size" scope="col">
                Comment
              </th>
              <th className="over-col-size" scope="col">
                Distance
              </th>
              <th className="over-col-size" scope="col">
                Source
              </th>
              <th className="over-col-size" scope="col">
              Already Business Brand
              </th>
              <th className="over-col-size" scope="col">
              Already Working Details
              </th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!distributorList &&
              distributorList.map((data, index) => {
                return (
                  <tr key={data?._id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data.address?.town +
                        "," +
                        data.address?.district +
                        "," +
                        data.address?.state}
                    </td>
                    <td>{data?.number}</td>
                    <td>
                      {moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td>{data?.userComment}</td>
                    <td>{data?.comment}</td>
                    <td>{data?.distance}</td>
                    <td> {data?.utm_source?.length > 0 && data?.utm_source} {data?.utm_Medium ? `/` : null} {data?.utm_Medium}</td>
                    <td>{data?.alreadyBusinessBrand}</td>
                    <td>{data?.exactParty}</td>
                    <td>
                      <span
                        className={
                          (data.status == "Pending" && "text-primary") ||
                          (data.status == "Selected" && "text-warning") ||
                          (data.status == "Confirmd" && "text-success") ||
                          (data.status == "Discussion" && "text-secondary") ||
                          (data.status == "ForFuture" && "text-info") ||
                          (data.status == "Reject" && "text-danger") ||
                          (data.status == "Read" && "text-dark") ||
                          (data.status == "Warm" && "text-pink") ||
                          (data.status == "Hot" && "text-blue")
                        }
                      >
                        <b>{data.status}</b>
                      </span>
                    </td>
                    <td>
                      {localStorage.getItem("userRole") === "1" && (
                        <>
                          {localStorage.getItem("x-auth-token") &&
                            localStorage.getItem("userRole") === "1" && (
                              <>
                                <div className="outer d-flex">
                                  <div className="">
                                    <Button
                                      className="m-1"
                                      variant="danger"
                                      onClick={async () => {
                                        await dispatch(
                                          deleteDistributorRecord(data?._id)
                                        );
                                        dispatch(getDistributorList());
                                      }}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </Button>
                                  </div>
                                  <div className="">
                                    <DistributorAdd
                                      town={data.address?.town}
                                      district={data.address?.district}
                                      state={data.address?.state}
                                      number={data?.number}
                                      email={data.email}
                                      name={data?.name}
                                      firmName={data?.firmName}
                                      GSTIN={data?.GSTIN}
                                      _id={data?._id}
                                      mode={"Online"}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                        </>
                      )}
                      <DistributorView
                        name={data?.name}
                        address={data?.address}
                        firmName={data?.firmName}
                        gstin={data?.GSTIN}
                        email={data.email}
                        number={data?.number}
                        userComment={data?.userComment}
                      />
                      {localStorage.getItem("x-auth-token") &&
                        (localStorage.getItem("userRole") === "1" ||
                          localStorage.getItem("userRole") === "5" ||
                          localStorage.getItem("userRole") === "9" || localStorage.getItem("userRole") === "17") && (
                          <DistributorUpdate
                            userStatus={data?.status}
                            userComment={data?.comment}
                            id={data?._id}
                            filterStatus={userStatus}
                            states={states}
                            district={district}
                            startDate={startDate}
                            endDate={endDate}
                            userDistance={data?.distance}
                            userExactParty={data?.exactParty}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!distributorListBlank && distributorListBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getDistributorList({
                    page: page + 1,
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default DistributorSuperstockistList;
