import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import logo from "../Images/logo.png";
import {
  distributorRegister,
  getStatesList,
  getDistrictList,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  number: yup.string().min(10).max(10).required(),
  firmName: yup.string(),
  town: yup.string().required(),
  userComment: yup.string(),
  district: yup.string().required(),
  state: yup.string().required(),
  GSTIN: yup.string().required(),
  alreadyBusinessBrand : yup.string().required(),
});

function DistributorSuperstockist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { distributorMsg, statesList, getDistrict } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    dispatch(getStatesList());
    // setTimeout(() => {
    // localStorage.getItem("distributorAply") == 200 && navigate("/thankyou-distributor")},500)
  }, []);
  return (
    <>
      <div className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="bg-white shadow rounded p-5">
                <Row className="text-center">
                  <img className="width-auto mb-3 m-auto logoForm" src={logo} />
                  <h3 className="mb-4">
                    Get Distributor
                    <br /> Please Fill This Form
                  </h3>
                </Row>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                      const apiResp = await dispatch(distributorRegister(values));
                      if(apiResp?.payload?.status){
                        localStorage.removeItem("utm_source")
                        localStorage.removeItem("utm_Medium")
                        resetForm({ values: "" });
                        setTimeout(() => {
                        localStorage.getItem("distributorAply") == 200 &&
                            navigate("/thankyou-distributor?page=distributor")
                          }, 1000)
                      }
                    }} 
                    initialValues={{
                      name: "",
                      email: "",
                      number: "",
                      firmName: "",
                      town: "",
                      userComment: "",
                      district: "",
                      state: "",
                      GSTIN: "",
                      alreadyBusinessBrand:'',
                      utm_source: localStorage.getItem("utm_source")?.length > 0 ? localStorage.getItem("utm_source") : 'Organic',
                      utm_Medium: localStorage.getItem("utm_Medium"),
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Name of the Firm</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name of the Firm"
                                name="firmName"
                                value={values.firmName}
                                onChange={handleChange}
                                isInvalid={!!errors.firmName}
                              />
                              {/* <Form.Control.Feedback type="invalid">
                                {errors.firmName}
                              </Form.Control.Feedback> */}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={4} sm={12}>
                            <Form.Label>State</Form.Label>
                            <br />
                            <Form.Control
                              style={{ textTransform: "capitalize" }}
                              as="select"
                              onChange={async (e) => {
                                setFieldValue("state", e.target.value);
                                dispatch(getDistrictList(e.target.value));
                              }}
                              name="state"
                              className="form-control"
                              value={values.state}
                              isInvalid={!!errors.state}
                            >
                              <option value="">Select State</option>
                              {statesList.map((data, index) => {
                                return (
                                  <>
                                    <option
                                      className="first-latter-capital"
                                      key={index}
                                      value={data.state}
                                    >
                                      {data.state}
                                    </option>
                                  </>
                                );
                              })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.state}
                            </Form.Control.Feedback>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Label>District</Form.Label>
                            <br />
                            <Form.Control
                              style={{ textTransform: "capitalize" }}
                              as="select"
                              name="district"
                              onChange={handleChange}
                              value={values.district}
                              className="form-control"
                              isInvalid={!!errors.district}
                            >
                              <option value="">Select District</option>
                              {!!getDistrict &&
                                getDistrict.map((data, index) => {
                                  return (
                                    <>
                                      <option
                                        className="first-latter-capital"
                                        value={data}
                                        key={index}
                                      >
                                        {data}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.district}
                            </Form.Control.Feedback>
                          </Col>
                          <Col md={4} sm={12}>
                          <Form.Group>
                              <Form.Label>Town</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Town"
                                name="town"
                                value={values.town}
                                onChange={handleChange}
                                isInvalid={!!errors.town}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.town}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                          <Form.Group>
                              <Form.Label>Already Business Brand Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Already Business Brand Name"
                                name="alreadyBusinessBrand"
                                value={values.alreadyBusinessBrand}
                                onChange={handleChange}
                                isInvalid={!!errors.alreadyBusinessBrand}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.alreadyBusinessBrand}
                              </Form.Control.Feedback>
                            </Form.Group>
                            
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>
                                Mobile Number{" "}
                                <span className="text-secondary">
                                  (Country Code Not Required)
                                </span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Mobile Number"
                                name="number"
                                value={values.number}
                                onChange={handleChange}
                                isInvalid={!!errors.number}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.number}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>GSTIN</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="GSTIN"
                                name="GSTIN"
                                value={values.GSTIN}
                                onChange={handleChange}
                                isInvalid={!!errors.GSTIN}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.GSTIN}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group>
                              <Form.Label>Write any message.</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write any message........"
                                name="userComment"
                                value={values.userComment}
                                onChange={handleChange}
                                as="textarea"
                                rows={3}
                                isInvalid={!!errors.userComment}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                  {/* <p>
                    <b>{distributorMsg}</b>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DistributorSuperstockist;
